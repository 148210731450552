import React, { useState } from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Modal from '../../components/Modal'

import '../../styles/gerencia.scss'
import GerenciaProyectos from '../../images/2gerencia_proyectos_nf.png'
import MapaColombia from '../../images/colombia.png'

export default function Gerencia({ children, location }) {
	const [ openModal, setOpenModal ] = useState(false)
	const [ city, setCity ] = useState('')

	function handleClick(e) {
		setCity(e.target.id)
		setOpenModal(true)
	}
	function closeModal() {
		if (openModal) {
			setOpenModal(false)
			setCity('')
		}
	}

	return (
		<Layout currentPath={location}>
			<SEO title="Gerencia de Proyectos | Proyectos" />
			<div className="gerencia" onClick={closeModal}>
				{openModal ? <Modal closeModal={closeModal} city={city} /> : null}
				<Link to="/#proyectos" className="back">
					&larr;Volver
				</Link>
				<div className="gerencia__title">
					<div className="gerencia__texture" />
					<h1>GERENCIA DE PROYECTOS</h1>
				</div>
				<div className="gerencia__content">
					<div className="gerencia__content__leftbar" />
					<img className="gerencia__logo" src={GerenciaProyectos} alt="Grupo Ínsula Gerencia de Proyectos" />
					<p>
						Conoce los casos de éxito más relevantes en los que hemos trabajado a nivel nacional. Haz click
						para conocer más.
					</p>
					<div className="gerencia__content__rightbar" />
				</div>
				<div className="gerencia__map-container">
					<img className="gerencia__mapa" src={MapaColombia} alt="Grupo Insula Mapa de Colombia" />
					<p className="gerencia__map-item gerencia__map-item--sm" id="sm" onClick={handleClick}>
						SANTA MARTA
					</p>
					<p className="gerencia__map-item gerencia__map-item--gr" id="gr" onClick={handleClick}>
						GIRARDOT <span id="gr" onClick={handleClick}>- CUNDINAMARCA</span>
					</p>
					<p className="gerencia__map-item gerencia__map-item--ar" id="ar" onClick={handleClick}>
						ARMENIA
					</p>
					<p className="gerencia__map-item gerencia__map-item--bg" id="bg" onClick={handleClick}>
						BOGOTÁ
					</p>
					<p className="gerencia__map-item gerencia__map-item--ca" id="ca" onClick={handleClick}>
						CALI <span id="ca" onClick={handleClick}>- VALLE DEL CAUCA</span>
					</p>
					<p className="gerencia__map-item gerencia__map-item--vc" id="vc" onClick={handleClick}>
						VALLE DEL CAUCA
					</p>

					<p className="gerencia__map-item gerencia__map-item--sq" id="sq" onClick={handleClick}>
						SANT. DE QUILICHAO <span id="sq" onClick={handleClick}>- CAUCA</span>
					</p>
				</div>
			</div>
		</Layout>
	)
}
