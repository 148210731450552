import React from 'react'

import '../styles/modal-card.scss'

export default function ModalCard({ item }) {
	return (
		<div className={`modal-card ${item.title === "YOY VITAPARQUES" && 'yoy-card'}`}>
			<div className="modal-card__inner-container">
				<div className="modal-card__header">
					<img src={item.img} alt={item.title} />
					<div>
						<h4>{item.title}</h4>
						<a href={item.uri} target="_blank" rel="noopener noreferrer">
							{item.url}
						</a>
					</div>
				</div>
				{item.text.map((text, idx) => (
					<p className="modal-card__text" key={idx}>{text}</p>
				))}
			</div>
		</div>
	)
}
