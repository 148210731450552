import React from 'react'

import ModalCard from './ModalCard'

import '../styles/modal.scss'

import PuntaGaira from '../images/punta_gaira.jpg'
import Huayra from '../images/huayra.jpg'
import ReservaModelia from '../images/reserva_modelia.jpg'
import Avanti from '../images/avanti.jpg'
import Koa from '../images/koa.jpg'
import Yoy from '../images/yoy.jpg'
import Breck from '../images/breck_104.png'
import Altavista from '../images/altavista.jpg'
import Liv37 from '../images/liv_37.jpg'
import LivingPenon from '../images/living_penon.jpg'
import Praderas from '../images/praderas.jpg'
import Mesetas from '../images/mesetas.jpg'

import YoyVitaClub from '../images/vitaclub.png';
import Vitanica from '../images/vitanica.png';
import AvantiLoft from '../images/avanti-loft.png';
import AvantiTower from '../images/avanti-tower.png';

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Modal({ closeModal, city }) {
	const sm = [
		{
			title: 'PUNTA GAIRA',
			url: 'www.puntagaira.co',
			uri: 'https://puntagaira.com.co',
			img: PuntaGaira,
			text:[
				'Estructuramos este proyecto para BREK S.A.S., innovamos al ser el primer proyecto de Vivienda de Interés Social a 5 minutos de la playa en Santa Marta, compuesto por 112 apartamentos en dos torres con increíbles zonas sociales. En su lanzamiento se vendieron más de la mitad de los apartamentos.'
			]
		},
		{
			title: 'HUAYRA',
			url: 'www.huayra.com.co',
			uri: 'https://huayra.com.co',
			img: Huayra,
			text:[
				'Ubicado en segunda linea de playa salguero en Santa Marta, estructuramos este proyecto para BREK CONSTRUCCIONES, con una arquitectura impactante y dotado como un hotel 5 estrellas, desarrollamos apartasuites perfectas para vivir, para rentar por temporadas o como segunda vivienda, acompañado de un imponente comercio sobre todo el frente del lote. Es el proyecto lider en ventas en Santa Marta.'
			]
		}
	]

	const bg = [
		{
			title: 'RESERVA DE MODELIA',
			url: '',
			uri: '',
			img: ReservaModelia,
			text:[
				'Estructuramos para GRUPO JUFESAN, un proyecto ubicado en Modelia en la ciudad de Bogotá, con un concepto de Coliving, desarrollamos un producto a la medida de los jóvenes. El 85% del proyecto fue vendido en 3 meses.'
			]
		},

		{
			title: 'BREK 104',
			url: '',
			uri: '',
			img: Breck,
			text:[
				'Estructuración del proyecto Breck 104, ubicado en el norte de bogotá, en el barrio santa paula. Es un edificio de 98 suites desde 22,7 m2. Es un proyecto que tiene por concepto el coliving.'
			]
		},

		{
			title: 'AVANTI TOWER',
			url: '',
			uri: '',
			img: AvantiTower,
			text:[
				'40 apartamentos vendidos, Estructuración y gerencia'
			]
		},
		{
			title: 'AVANTI LOFT',
			url: 'Clic Para ver Proyecto',
			uri: 'http://crear.pixju.com/proyectos/avanti-loft/',
			img: AvantiLoft,
			text:[
				'50 apartamentos vendidos en 3 meses, Estructuración y gerencia'
			]
		},
		{
			title: 'AVANTI 98',
			url: 'www.avanti98.alliancesc.co',
			uri: 'https://avanti98.alliancesc.co',
			img: Avanti,
			text: [
				'Estructuramos y hacemos la gerencia para CONSTRUCCIONES CREAR S.A.S., un proyecto que ubicado en Fontibón en la ciudad de Bogotá, tuvimos el reto de estructurar un proyecto exitoso rodeado de competencia de las constructoras más grandes de Bogotá, lo que nos llevó a innovar e identificar las necesidades insatisfechas del mercado, y así desarrollar un producto exclusivo  con precios entre los $90 y $139 mlls. El 100% del proyecto fue vendido en 4 meses.'
			]
		},
	]

	const ar = [
		{
			title: 'KOA',
			url: 'www.constructora1a.com/koa-apartamentos',
			uri: 'https://constructora1a.com/koa-apartamentos',
			img: Koa,
			text:[
				'Participamos en evaluación del proyecto y la definición del producto para CONSTRUCTORA 1A, en la ciudad de Armenia, donde logramos un producto de vivienda de interés social con múltiples alternativas de inversión y con multiples diferencias absolutas respecto a la competencia, como la cantidad de parqueaderos, las zonas comunes y el sistema constructivo aporticado "tradicional".'
			]
		}
	]

	const vc = [
		{
			title: 'YOY VITAPARQUES',
			url: 'www.yoyvitaparques.com',
			uri: 'https://yoyvitaparques.com',
			img: Yoy,
			text:[
				'Ubicado en Jamundí - Valle al sur de la ciudad de Cali, estructuramos para CONSTRUCTORA XIUDAD , un conjunto cerrado campestre de lotes urbanizados un concepto único compuesto de 10 PARQUES 1 HOGAR, donde todos los miembros del hogar pueden conectarse con la naturaleza en más de 12.000 m2 de zonas verdes. Su primera etapa fue vendida en menos de 3 meses.',

				'Interventoría, revisión y auditoria del proyecto YOY VITAPARQUES y a todos los procesos contructivos con el fin de garantizar el cumplimiento de la norma y de los diseños aprobados por la curaduría.'
			]
		},
		{
			title: 'PRADERAS DE LA HACIENDA',
			url: '',
			uri: '',
			img: Praderas,
			text:[
				'Gerencia del proyecto Praderas de la hacienda, ubicado en el Bordo – Cauca. Es un proyecto de 390 lotes.'
			]
		},
		{
			title: 'MESETAS',
			url: '',
			uri: '',
			img: Mesetas,
			text:[
				'Estructuración del proyecto Mesetas, es un proyecto de 162 lotes campestres ubicado en Mercaderes – Cauca.'
			]
		},
		{
			title: 'YOY VITACLUB',
			url: '',
			uri: '',
			img: YoyVitaClub,
			text:[
				' Estructuración, gerencia e interventoría, 68 lotes ubicados en Jamundí.'
			]
		},
		
	]

	const gr = [
		{
			title: 'ALTAVISTA',
			url: '',
			uri: '',
			img: Altavista,
			text:[
				'Gerencia y estructuración del proyecto Altavista ubicado en el norte de Girardot, en el barrio portachuelo. Es un conjunto residencial de 34 unidades de vivienda y zonas social como piscinas, parques infantiles, zonas verdes.'
			]
		}
	]

	const ca = [
		{
			title: 'LIV 37',
			url: '',
			uri: '',
			img: Liv37,
			text:[
				'Interventoría, revisión y auditoria del proyecto Liv 37 y a todos los procesos contructivos con el fin de garantizar el cumplimiento de la norma y de los diseños aprobados por la curaduría. Ubicado en el norte de Cali, en el barrio prados del norte. Es un edificio de 11 apartamentos.'
			]
		},
		{
			title: 'LIVING IN PEÑON',
			url: '',
			uri: '',
			img: LivingPenon,
			text:[
				'Estructuración y comercialización del proyecto inmobiliario enfocado a inversionistas. Ubicado en el oeste de Cali, en el barrio El Peñon. Es un edificio de 20 apartasuites.'
			]
		}
	]

	const sq = [
		{
			title: 'VITÁNICA',
			url: 'www.vitanica.co',
			uri: 'https://vitanica.co/',
			img: Vitanica,
			text:[
				'Estructuración, gerencia e interventoría, 187 lotes ubicados en Santander de Quilichao'
			]
		}
	]
	function checkCity() {
		switch (city) {
			case 'sm':
				return sm

			case 'bg':
				return bg

			case 'ar':
				return ar

			case 'vc':
				return vc

			case 'gr':
				return gr

			case 'ca':
				return ca

			case 'sq':
				return sq

			default:
				return sm
		}
	}

	function getCols() {
		if (city === 'ar' || city === 'gr' || city === 'sq' ) {
			return true
		} else return false
	}

	var settings = {
		dots: false,
		arrows: false,
		infinite: true,
		accessibility: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	return (
		<div className={`modal ${getCols() ? 'modal--col' : ''}`}>
			<span className="modal__close" onClick={closeModal}>
				X
			</span>
			<div className="modal__cards-container">
				{checkCity().map((item, idx) => {
					return <ModalCard item={item} key={idx} />
				})}
			</div>

			<div className="modal__cards-container-mobile">
				{getCols() ? (
					checkCity().map((item, idx) => {
						return <ModalCard item={item} key={idx} />
					})
				) : (
					<Slider {...settings} className="sliders-container">
						{checkCity().map((item, idx) => {
							return <ModalCard item={item} key={idx} />
						})}
					</Slider>
				)}
			</div>
		</div>
	)
}
